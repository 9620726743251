import React, { useState } from "react";
import VERRE_SECURITE_BATIMENT from "../../pictures/verre_securite_batiment_background.jpg";
import VERRE_SECURITE_BATIMENT_1 from "../../pictures/verre_securite_batiment_description_1_background.jpg";
import VERRE_SECURITE_BATIMENT_2 from "../../pictures/verre_securite_batiment_description_2_background.jpg";
import VERRE_SECURITE_BATIMENT_3 from "../../pictures/verre_securite_batiment_decription_4_background.jpg";
import VERRE_SECURITE_BATIMENT_4 from "../../pictures/verre_securite_batiment_decription_5_background.jpg";
import VERRE_SECURITE_BATIMENT_5 from "../../pictures/verre_securite_batiment_decription_6_background.jpg";
import VERRE_SECURITE_BATIMENT_6 from "../../pictures/verre_securite_batiment_decription_7_background.jpg";

function ProduitAfricaverSecuriteBatiment() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style6, setstyle6] = useState({ div: null, image: null });
    const [style7, setstyle7] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>verre sécurtité batiment</h2>
                <h3>Caractéristiques</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Caractéristiques</th>
                            <th>Verre feuilleté</th>
                            <th>Verre trempé</th>
                            <th>Verre blindé anti-efraction</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Epaisseur</td>
                            <td>6 à 60 mm</td>
                            <td>4 à 12 mm</td>
                            <td>22 à 60mm</td>
                        </tr>
                        <tr>
                            <td>Dim max</td>
                            <td>2200 x 4000mm</td>
                            <td>2400 x 1240mm</td>
                            <td>/</td>
                        </tr>
                        <tr>
                            <td>Dim min</td>
                            <td>400 x 400 mm</td>
                            <td>200 x 200 mm</td>
                            <td>/</td>
                        </tr>
                        <tr>
                            <td>Teintes</td>
                            <td>Clair, teinté, réfléchissant, émaillé</td>
                            <td>clair, teinté et réfléchissant</td>
                            <td>Clair, teinté</td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(3,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style7.div}>
                            <button
                                onClick={() =>
                                    setstyle7({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style7.image}
                                src={VERRE_SECURITE_BATIMENT_6}
                                alt=""
                                onClick={() => {
                                    setstyle7({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_SECURITE_BATIMENT_1}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_SECURITE_BATIMENT_2}
                                alt=""
                                onClick={() => {
                                    setstyle3({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_SECURITE_BATIMENT_3}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_SECURITE_BATIMENT_4}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style6.div}>
                            <button
                                onClick={() =>
                                    setstyle6({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style6.image}
                                src={VERRE_SECURITE_BATIMENT_5}
                                alt=""
                                onClick={() => {
                                    setstyle6({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAfricaverSecuriteBatiment;
