import React from "react";
import AFRICAVER1 from "../../pictures/africaver_1.jpg";
import AFRICAVER2 from "../../pictures/africaver_2.jpg";

function Africaver() {
    return (
        <div className="filiale-container">
            <div className="cleaner"></div>
            <div className="filiale-title">
                <h2>Filiale Africaver</h2>
            </div>
            <div className="filiale-grid">
                <div className="filiale-grid-left">
                    <div class="filiale-web-description">
                        <p>
                            la société africaine du verre Africaver Spa a été
                            créee en 1986
                        </p>
                        <p>L'usine est composée de 02 activités : </p>
                        <p> Verre de sécurité mise en exploitation en 1992</p>
                        <p> Verre coulé dont :</p>
                        <p> - Ligne de verre imprimé en 1994</p>
                        <p> - Ligne de silicate de soude N°01 en 1997</p>
                        <p> - Ligne de silicate de soude N°02 en 1998 </p>
                    </div>
                    <div className="filiale-description-flex">
                        <div>
                            <h4>Dénomination :</h4>
                            <p>Société Africaine du Verre AFRICAVER SPA</p>
                        </div>
                        <div>
                            <h4>Adresse :</h4>
                            <p>Zone industrielle de Taher BP 06 JIJEL </p>
                        </div>
                        <div>
                            <h4>Date de création :</h4>
                            <p>1986</p>
                        </div>
                        <div>
                            <h4>Tél :</h4>
                            <p>034.54.97.96/98</p>
                        </div>
                        <div>
                            <h4>Fax :</h4>
                            <p>034.54.97.96/98</p>
                        </div>
                        <div>
                            <h4>E-mail :</h4>
                            <p>africaverjijel@yahoo.fr</p>
                        </div>
                        <div>
                            <h4>Site Web :</h4>
                            <p>www.africaver.com.dz</p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>Activité</h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>Les activités principales de la filiale :</p>
                            <p style={{ fontWeight: "bold" }}>
                                • Verre sécurité:
                            </p>
                            <p>
                                - Verre sécurité bâtiment (verre feuilleté et
                                trempé)
                            </p>
                            <p>- Verre blindé antieffraction et anti balles,</p>
                            <p>
                                - Vitrage automobile (pare-brise – vires
                                latérales – lunettes arrières)
                            </p>
                            <p style={{ fontWeight: "bold" }}>
                                • Verre coulé :
                            </p>
                            <p>- Verre imprimé</p>
                            <p>- Silicate de soude solide et liquide.</p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>
                            Les domaine d'activité des produits de Africaver
                            sont variés:
                        </h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>
                                • Secteur du bâtiment : verre feuillété et verre
                                trempé
                            </p>
                            <p>
                                • Secteur automobile : pare brise, vitres
                                latèrales, lunettes arrières et autres...
                            </p>
                            <p>
                                • Secteur de l'ameublement et du bâtiment :
                                verre imprimé dans divers motifs
                            </p>
                            <p>
                                • Secteur de la chimie industrielle : silicate
                                de soude pour la fabrication de détergents,
                                secteur de la fonderie, de la céramique et
                                verrerie
                            </p>
                        </div>
                    </div>
                </div>
                <div className="filiale-grid-right">
                    <div className="filiale-description-image">
                        <img src={AFRICAVER1} alt="" />
                    </div>
                    <div className="filiale-sub-description-image">
                        <img src={AFRICAVER2} alt="" />
                    </div>
                    <div
                        className="filiale-sub-description-image"
                        style={{ marginTop: "20px" }}
                    >
                        <img src="/images/SERIGRAPHIE.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default Africaver;
