import React from "react";

function Nover() {
    return (
        <div className="filiale-container">
            <div className="cleaner"></div>
            <div className="filiale-title">
                <h2>Filiale Nover</h2>
            </div>
            <div className="filiale-grid">
                <div className="filiale-grid-left">
                    <div class="filiale-web-description">
                        <p>Le démarrage du projet a eu lieu en 1992.</p>
                        <p>
                            la création de la filiale Nover Spa a eu lieu en
                            1995 avec l'apport de 07 actionnaires (ENAVA (45%)
                            ERCO (10%) ECDE (10%) SAIDAL (10%) ENAB (10%)
                            ENOPHARM (05%) Fonds de participation (10%))
                        </p>
                        <p>
                            En septembre 1996, mise en service du four D (50
                            tonnes)
                        </p>
                        <p>
                            En Octobre 1998, mise en service du four C (10
                            tonnes)
                        </p>
                        <p>
                            En Novembre 2003, mise en service du four B (50
                            tonnes)
                        </p>
                        <p>
                            Les actions de renouvellement des fours engagées
                            entre 2003,2004 et 2014 ont été à l'origine de la
                            modernisation d'une partie des installations, ce qui
                            a permis à la filiale d'investir des parts de marché
                            dans de nouvelles gammes des segments
                            agroalimentaires et d'autres créneaux tels que la
                            verrerie de table.
                        </p>
                        <p>
                            A ce jour il ne reste que 02 actionnaires (ENAVA 96%
                            et SAIDAL 04%)
                        </p>
                    </div>
                    <div className="filiale-description-flex">
                        <div>
                            <h4>Dénomination :</h4>
                            <p>Nouvelle Verrerie de Chlef NOVER SPA</p>
                        </div>
                        <div>
                            <h4>Adresse :</h4>
                            <p>Zone industrielle de Oued Sly BP 194 CHLEF</p>
                        </div>
                        <div>
                            <h4>Date de création :</h4>
                            <p>1995</p>
                        </div>
                        <div>
                            <h4>Tél :</h4>
                            <p>027. 71.07.06 / 71.05.63</p>
                        </div>
                        <div>
                            <h4>Fax :</h4>
                            <p>027.71.05.66</p>
                        </div>
                        <div>
                            <h4>E-mail :</h4>
                            <p>contact@nover.dz / commercial@nover.dz</p>
                        </div>
                        <div>
                            <h4>Site Web :</h4>
                            <p>www.nover-dz.com</p>
                        </div>
                        <div>
                            <h4>Domaine d’activité: </h4>
                            <p>
                                Production et commercialisation du Verre Creux
                                et Verre Pressé
                            </p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>Activités</h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>Les activités principales :</p>
                            <p>
                                • Verre creux (emballages en bouteilles pour les
                                jus, limonades, et bocaux pour divers produits
                                de conserve ( Smen, miel, pâte à tartiner,
                                confitures, sauces et autres) destinés pour
                                l’industrie agroalimentaire,
                            </p>
                            <p>
                                • Flaconnage destiné à l’industrie
                                pharmaceutique et parapharmaceutique.
                            </p>
                            <p>
                                • Verre pressé (verres de table, assiettes,
                                gobelets, choppe).
                            </p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>
                            Les domaine d'activité des produits de NOVER sont
                            variés:
                        </h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>
                                • Secteur agroalimentaire : conditionnement
                                alimentaire de boissons (jus, limonade eaux
                                minérales) et de produits de conserve (Smen,
                                sauce, confitures, miel, olives), et verres,
                                assiettes, gobelets, etc….,)
                            </p>
                            <p>
                                • Secteur pharmaceutique et parapharmaceutique :
                                conditionnement pour différents produits : sirop
                                en flacons de différentes contenances.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="filiale-grid-right">
                    <div className="filiale-description-image">
                        <img src="/images/Entree_usine_Nover.jpg" alt="" />
                    </div>
                    <div className="filiale-sub-description-image">
                        <img src="/images/Machine-de-production.jpg" alt="" />
                    </div>
                    <div
                        className="filiale-description-image"
                        style={{ marginTop: "20px" }}
                    >
                        <img
                            src="/images/Convoyage_produits_finis.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div className="cleaner"></div>
        </div>
    );
}

export default Nover;
