import React from "react";
import MediaQuery from "react-responsive";

function QuiSommeNous() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>Identification de l’entreprise</h2>
                    <p>
                        <span style={{ color: "#000" }}>Dénomination :</span>{" "}
                        Entreprise Nationale des Verres et Abrasifs par
                        abréviation{" "}
                        <span style={{ color: "#000" }}>
                            &quot;ENAVA SPA&quot;
                        </span>{" "}
                        créée en décembre 1982, faisant partie du portefeuille
                        du Holding ACS{" "}
                        <span style={{ color: "#000" }}>
                            ‘’Algérie Chemicals Service’’
                        </span>
                    </p>
                    <p>
                        <span style={{ color: "#000" }}>Statut :</span> EPE /
                        SPA érigée en Groupe Industriel en 1996, il est composé
                        de 04 filiales.
                    </p>
                    <p>
                        <span style={{ color: "#000" }}>
                            Au Capital Social :
                        </span>
                        154.260.000,00 DA.
                    </p>
                    <p>
                        <span style={{ color: "#000" }}>Actionnaire :</span>{" "}
                        Holding Algeria Chemical Spécialities « ACS Spa »
                    </p>
                    <p>
                        <span style={{ color: "#000" }}>Localisation:</span>{" "}
                        Siège social ZHUN USTO Oran BP 4073, Ibn Rochd Oran
                    </p>
                    <p>
                        <span style={{ color: "#000" }}>
                            Domaine d’activité :
                        </span>{" "}
                        Production et commercialisation des Verres et Abrasifs
                    </p>

                    <h2 style={{ marginTop: "40px" }}>Historique</h2>
                    <h3>Avant l’Indépendance</h3>
                    <p>
                        L’Industrie du verre en Algérie était connue sous la
                        désignation de Verrerie de l’Afrique du Nord ‘’V.A.N’’ à
                        travers l’usine d’Oran propriété du Groupe Saint Gobain
                        (France).
                    </p>
                    <p>
                        Construite en 1947, elle fournissait de la bouteillerie
                        aux pays du Maghreb.
                    </p>
                    <h3>Après l’Indépendance</h3>
                    <p>
                        1966, Création de la Société Nationale de l’Industrie du
                        Verre ‘’S.N.I.V’’.
                    </p>
                    <p>
                        1973, Restructuration du secteur industriel et se
                        traduisant par l’absorption de la SNIV par la Société
                        Nationale des Industrie Chimiques ‘’S.N.I.C’’.
                    </p>
                    <p>
                        1982, Restructuration de la SNIC et la création de
                        l’Entreprise Nationale des Verres et Abrasifs
                        ‘’E.NA.V.A’’ composée de 04 unités dont :
                    </p>
                    <p>- Unité Verre d’Oran.</p>
                    <p>- Unité Miroiterie de Thenia.</p>
                    <p>- Unité Abrasifs de Saida.</p>
                    <p>- Projet Verre Plat de Jijel.</p>
                    <p>
                        1990, Cette phase a connu également e lancement de 02
                        projets,
                    </p>
                    <p>- Projet verre creux de Chlef </p>
                    <p>- Projet verre creux et verre ophtalmique (SOVEST)</p>
                    <p>1995, Création des filiales Nover et Sovest</p>
                    <p>
                        1997, Restructuration de l’Entreprise Nationale des
                        Verres et Abrasifs en Groupe Industriel composé de 06
                        filiales.
                    </p>
                    <p>- Alver Spa Oran</p>
                    <p>- Nover Spa Chlef.</p>
                    <p>- Africaver Jijel</p>
                    <p>- Somiver Spa Thenia.</p>
                    <p>- Sovest Spa (Tebessa – El Khroub)</p>
                    <p>- ABRAS Spa Saida.</p>
                    <p>
                        1998, la filiale Sovest Spa (unité Tebessa – unité El
                        Khroub) fût cédée à la BEA
                    </p>
                    <p>
                        2011, la filiale ALVER d’Oran fût cédée à Saint Gobain
                        France dans le cadre de la privatisation.
                    </p>

                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>Historique</h2>
                    <p>
                        En abordant la société ENAVA comme organisme d’accueil
                        de mon stage pratique il est nécessaire de donner un
                        aperçu de la société depuis sa création jusqu'à nos
                        jours.
                    </p>
                    <p>
                        La première pierre de l'usine, appelée auparavant
                        verrerie de l'Afrique du nord (Saint Gobain), a été
                        posée le 08 novembre 1942.
                    </p>
                    <p>
                        Suite aux événements de la deuxième guerre mondiale, le
                        manque en verre se faisait sentir impérieusement, ce qui
                        a conduit les services de production de l'époque de
                        soutenir la poursuite de la construction de l'usine qui
                        était mise en route effectivement en 1947 après la
                        réception des trois machines "LYNCH", de la part des
                        verreries métropolitains (France).
                    </p>
                    <p>
                        Le premier four a été allumé le 19/01/1947. Vingt jours
                        plus tard, la première bouteille est fabriquée et
                        l'inauguration officielle a été faite le 19/04/1947.
                    </p>
                    <p>
                        En 1968 la société nationale des industries du verre
                        (SNIV) à été crée qui sera par la suite dissoute.
                    </p>
                    <p>
                        En 1973, après dissolution de la SNIV, rattachement à la
                        SNIC (la société nationale des industries chimiques).
                    </p>
                    <p>
                        « ENAVA » l’entreprise nationale des verres et abrasifs
                        est issue de la restructuration financière et organique
                        de la SNIC et crée par décret en 1982.{" "}
                    </p>
                    <p>
                        ENAVA,unique groupe industriel dans le domaine du verre
                        et abrasifs en Algérie, était constituer en 1983 sur le
                        patrimoine de 3 unité de production de l’ex SNIC, soit
                        respectivement l’unité Verre d’Oran, l’unité Miroiterie
                        de Thenia, l’unité Abrasif de Saida.
                    </p>
                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default QuiSommeNous;
