import React from "react";
import MediaQuery from "react-responsive";

function Evenement() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>
                        Stand GROUPE ENAVA lors du salon de production Nationale
                        2022 à SAFEX Alger.
                    </h2>
                    <div
                        style={{
                            width: "full",
                            display: "grid",
                            gridTemplateColumns: "repeat(3,1fr)",
                            gap: "30px",
                            padding: "50px 0",
                        }}
                    >
                        <img
                            src="/evenements/20221214_101834.webp"
                            style={{ width: "100%" }}
                        />
                        <img
                            src="/evenements/20221214_101856.webp"
                            style={{ width: "100%" }}
                        />
                        <img
                            src="/evenements/20221214_101905.webp"
                            style={{ width: "100%" }}
                        />
                        <img
                            src="/evenements/20221214_101922.webp"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>Evenements</h2>
                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default Evenement;
