import React, {useState} from 'react'
import VERRE_SILICATE_DE_SOUDE from '../../pictures/verre_silicate_background.jpg'
import VERRE_SILICATE_DE_SOUDE_1 from '../../pictures/verre_silicate_descirption_1_background.JPG'
import VERRE_SILICATE_DE_SOUDE_2 from '../../pictures/verre_silicate_descirption_2_background.jpg'

function ProduitAfricaverSilicateDeSoude() {
    const divStyle = {width:'100%',height:'100%',backgournd:'#000',position:'fixed',top:'0',left:'0',zIndex:'100'};
    const imageStyle = {position:'absolute',width:'50%',top:'50%',left:'50%',transform:'translate(-50%,-50%)'};
    const [style1, setstyle1] = useState({div:null,image:null});
    const [style2, setstyle2] = useState({div:null,image:null});
    const [style3, setstyle3] = useState({div:null,image:null});
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>silicate de soude</h2>
                <h3>Caractéristiques</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Caractéristiques</th>
                        <th>Silicate vitreux</th>
                        <th>Silicate alcalin</th>
                        <th>Silicate neutre</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Densité degré baumé</td>
                        <td>/</td>
                        <td>40 à 46</td>
                        <td>38 à 40</td>
                    </tr>
                    <tr>
                        <td>Utilisation</td>
                        <td>Détergent</td>
                        <td>Adjuvant et l'industrie du forage</td>
                        <td>Céramique</td>
                    </tr>
                    <tr>
                        <td>Emballage</td>
                        <td>Big bag de 1000kg</td>
                        <td>Cubique ou citerne</td>
                        <td>Cubique ou citerne</td>
                    </tr>
                    </tbody>
                </table>
                <div class="produit-image-description" style={{gridTemplateColumns: 'repeat(3,1fr)'}}>
                    <div className='produit-image-sub-description'>
                        <div style={style1.div}>
                            <button onClick={()=>setstyle1({div:null,image:null})}>X</button>
                            <img style={style1.image} src={VERRE_SILICATE_DE_SOUDE} alt="" onClick={()=>{setstyle1({div:divStyle,image:imageStyle})}}/>
                        </div>
                    </div>
                    <div className='produit-image-sub-description'>
                        <div style={style2.div}>
                            <button onClick={()=>setstyle2({div:null,image:null})}>X</button>
                            <img style={style2.image} src={VERRE_SILICATE_DE_SOUDE_1} alt="" onClick={()=>{setstyle2({div:divStyle,image:imageStyle})}}/>
                        </div>
                    </div>
                    <div className='produit-image-sub-description'>
                        <div style={style3.div}>
                            <button onClick={()=>setstyle3({div:null,image:null})}>X</button>
                            <img style={style3.image} src={VERRE_SILICATE_DE_SOUDE_2} alt="" onClick={()=>{setstyle3({div:divStyle,image:imageStyle})}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    )
}

export default ProduitAfricaverSilicateDeSoude
