import React from "react";
import { NavLink } from "react-router-dom";
import VERRE_TREMPE from "../../pictures/verre_trempe_background.jpg";
import VERRE_SECURITE_AUTOMOBILE from "../../pictures/verre_securite_automobile_description_6_background.jpg";
import VERRE_SILICATE_DE_SOUDE from "../../pictures/verre_silicate_background.jpg";
import VERRE_SECURITE_BATIMENT from "../../pictures/verre_securite_batiment_background.jpg";
import VERRE_IMPRIME from "../../pictures/delta-2jpg.jpg";

function ProduitAfricaver() {
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="prd-container">
                <NavLink exact to="/ProduitAfricaverSecuriteAutomobile">
                    <div className="produit-presentation">
                        <img src={VERRE_SECURITE_AUTOMOBILE} alt="" />
                        <div className="produit-description">
                            <p>verre securité automobile</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAfricaverSecuriteBatiment">
                    <div className="produit-presentation">
                        <img src={VERRE_SECURITE_BATIMENT} alt="" />
                        <div className="produit-description">
                            <p>verre sécurtié batiment</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAfricaverTrempe">
                    <div className="produit-presentation">
                        <img src={VERRE_TREMPE} alt="" />
                        <div className="produit-description">
                            <p>verre trempé</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAfricaverSilicateDeSoude">
                    <div className="produit-presentation">
                        <img src={VERRE_SILICATE_DE_SOUDE} alt="" />
                        <div className="produit-description">
                            <p>verre silicate de soude</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAfricaverImprime">
                    <div className="produit-presentation">
                        <img src={VERRE_IMPRIME} alt="" />
                        <div className="produit-description">
                            <p>verre imprime</p>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAfricaver;
