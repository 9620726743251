import { NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";
import $ from "jquery";
import LOGO_ENAVA from "../../pictures/logo_enava.png";

function Navbar() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="navbar">
                    <div className="navbar-top">
                        <p>Enava</p>
                        <a href="tel:041822576" class="navbar-top-phone">
                            <i className="fas fa-mobile-alt"></i>
                            <span> 041 82 25 76/81</span>
                        </a>
                        <NavLink
                            exact
                            to="/contact"
                            className="navbar-top-button"
                        >
                            <i className="far fa-envelope"></i>
                            <span>contact</span>
                        </NavLink>
                    </div>
                    <div className="navbar-bottom">
                        <NavLink exact to="/">
                            <div>
                                <img
                                    className="navbar-logo"
                                    src="/images/logo-holding-acs.png"
                                    alt="logo-cfcg"
                                />
                            </div>
                        </NavLink>
                        <NavLink exact to="/">
                            <div className="navbar-logo-right">
                                <img
                                    className="navbar-logo-right"
                                    src={LOGO_ENAVA}
                                    alt="logo-cfcg"
                                />
                            </div>
                        </NavLink>
                        <NavLink className="navbar-home-icon" exact to="/">
                            <i class="fas fa-home-alt"></i>
                        </NavLink>
                        <div className="drop-down-menu" id="drop_down_menu_qsn">
                            <div className="navbar-link-cub"></div>
                            <p>Qui somme nous</p>
                            <div className="produit-menu" id="produit_menu_qsn">
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_qsn"
                                >
                                    <NavLink exact to="/Historique">
                                        <p>Historiques</p>
                                    </NavLink>
                                    <NavLink exact to="/MotDuPdg">
                                        <p>Mot du PDG</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="drop-down-menu" id="drop_down_menu_fll">
                            <div className="navbar-link-cub"></div>
                            <p>Nos filliales</p>
                            <div className="produit-menu" id="produit_menu_fll">
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_fll"
                                >
                                    <NavLink exact to="/Nover">
                                        <p>Nover</p>
                                    </NavLink>
                                    <NavLink exact to="/Africaver">
                                        <p>Africaver</p>
                                    </NavLink>
                                    <NavLink exact to="/Somiver">
                                        <p>Somiver</p>
                                    </NavLink>
                                    <NavLink exact to="/Abras">
                                        <p>Abras</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="drop-down-menu" id="drop_down_menu_prd">
                            <div className="navbar-link-cub"></div>
                            <p>Nos produits</p>
                            <div className="produit-menu" id="produit_menu_prd">
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_prd_2"
                                >
                                    <NavLink exact to="/ProduitNover">
                                        <p>produit nover</p>
                                    </NavLink>
                                    <div
                                        className="drop-down-menu-menu"
                                        id="drop_down_menu_menu_2"
                                    >
                                        <NavLink
                                            exact
                                            to="/produitNoverAgroAlimentaire"
                                        >
                                            <div>
                                                <p>agro-alimentaire</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitNoverPharmacetique"
                                        >
                                            <div>
                                                <p>pharmaceutique</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitNoverVerreriedetable"
                                        >
                                            <div>
                                                <p>verrerie de table</p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_prd_1"
                                >
                                    <NavLink exact to="/produitAfricaver">
                                        <p>produit africaver</p>
                                    </NavLink>
                                    <div
                                        className="drop-down-menu-menu"
                                        id="drop_down_menu_menu_1"
                                    >
                                        <NavLink
                                            exact
                                            to="/produitAfricaverSecuriteAutomobile"
                                        >
                                            <div>
                                                <p>verre sécurité automobile</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitAfricaverSecuriteBatiment"
                                        >
                                            <div>
                                                <p>verre sécurité batiment</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitAfricaverTrempe"
                                        >
                                            <div>
                                                <p>verre trempé</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitAfricaverImprime"
                                        >
                                            <div>
                                                <p>verre imprimé</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitAfricaverSilicateDeSoude"
                                        >
                                            <div>
                                                <p>silicate de soude</p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_prd_4"
                                >
                                    <NavLink exact to="/ProduitSomiver">
                                        <p>produit somiver</p>
                                    </NavLink>
                                    <div
                                        className="drop-down-menu-menu"
                                        id="drop_down_menu_menu_4"
                                    >
                                        <NavLink
                                            exact
                                            to="/produitSomiverLaboratoire"
                                        >
                                            <div>
                                                <p>verrerie de laboratoire</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/produitSomiverFaconnage"
                                        >
                                            <div>
                                                <p>
                                                    découpe et façonnage de
                                                    verre
                                                </p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                                <div
                                    className="produit-menu-menu"
                                    id="produit_menu_menu_prd_3"
                                >
                                    <NavLink exact to="/ProduitAbrasif">
                                        <p>produit abrasif</p>
                                    </NavLink>
                                    <div
                                        className="drop-down-menu-menu"
                                        id="drop_down_menu_menu_3"
                                    >
                                        <NavLink exact to="/ProduitAbrasifLiee">
                                            <div>
                                                <p>produit liée</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/ProduitAbrasifApplique"
                                        >
                                            <div>
                                                <p>produit appliqué</p>
                                            </div>
                                        </NavLink>
                                        <NavLink
                                            exact
                                            to="/ProduitAbrasifSablage"
                                        >
                                            <div>
                                                <p>produit sablage</p>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink exact to="/evenement" className="navbar-link">
                            <div className="navbar-link-cub"></div>
                            <p>Evènements</p>
                        </NavLink>
                        <NavLink exact to="/contact" className="navbar-link">
                            <div className="navbar-link-cub"></div>
                            <p>Contact</p>
                        </NavLink>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="navbar">
                    <div className="navbar-container">
                        <NavLink exact to="/">
                            <img
                                className="navbar-logo"
                                src={LOGO_ENAVA}
                                alt="logo-enava"
                            />
                        </NavLink>
                        <div className="navbar-button" id="navbar_button">
                            <div className="navbar-button-top">
                                <div id="navbar_button_1"></div>
                                <div id="navbar_button_2"></div>
                                <div id="navbar_button_3"></div>
                            </div>
                            <p>menu</p>
                        </div>
                    </div>
                </div>
                <div className="navbar-menu">
                    <div className="navbar-menu-top">
                        <NavLink exact to="/">
                            <div
                                className="navbar-menu-top-icon"
                                id="navbar_menu_top_icon"
                            >
                                <i class="fas fa-home-alt"></i>
                            </div>
                        </NavLink>
                        <div
                            className="navbar-menu-top-item"
                            id="navbar_menu_top_item_1"
                        >
                            <p>Qui somme nous</p>
                            <i className="fas fa-chevron-right"></i>
                        </div>
                        <div
                            className="navbar-menu-top-item-dropdown"
                            id="navbar_menu_top_item_1_dropdown"
                        >
                            <NavLink exact to="/Historique">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Historiques</p>
                                </div>
                            </NavLink>
                            <NavLink exact to="/MotDuPdg">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Mot du PDG</p>
                                </div>
                            </NavLink>
                        </div>
                        <div
                            className="navbar-menu-top-item"
                            id="navbar_menu_top_item_2"
                        >
                            <p>Nos produits</p>
                            <i className="fas fa-chevron-right"></i>
                        </div>
                        <div
                            className="navbar-menu-top-item-dropdown"
                            id="navbar_menu_top_item_2_dropdown"
                        >
                            <NavLink exact to="/ProduitAfricaver">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Produit Africaver</p>
                                </div>
                            </NavLink>
                            <NavLink exact to="/ProduitNover">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Produit Nover</p>
                                </div>
                            </NavLink>
                            <NavLink exact to="/ProduitSomiver">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Produit Somiver</p>
                                </div>
                            </NavLink>
                            <NavLink exact to="/ProduitAbrasif">
                                <div className="navbar-menu-top-item-dropdown-item">
                                    <i className="fas fa-chevron-right"></i>
                                    <p>Produit Abrasif</p>
                                </div>
                            </NavLink>
                        </div>
                        <NavLink exact to="/Contact">
                            <div
                                className="navbar-menu-top-item"
                                id="navbar_menu_top_item_3"
                            >
                                <p>Contact</p>
                            </div>
                        </NavLink>
                    </div>
                    <div
                        className="navbar-menu-bottom"
                        id="navbar_menu_bottom"
                    ></div>
                </div>
            </MediaQuery>
        </div>
    );
}

var windowWidth = window.innerWidth;
if (windowWidth > 767) {
    var lastScrollTop = 55;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st >= lastScrollTop) {
            $(".navbar").css("position", "fixed");
            $(".navbar-top").css("display", "none");
            $(".navbar-logo").removeClass("navbar-initial");
            $(".navbar-logo-right").removeClass("navbar-initial");
            $(".navbar-logo").addClass("navbar-fixed");
            $(".navbar-logo-right").addClass("navbar-fixed");
            $(".navbar-bottom").css("padding", "15px 20px");
        } else {
            $(".navbar").css("position", "");
            $(".navbar-top").css("display", "");
            $(".navbar-logo").removeClass("navbar-fixed");
            $(".navbar-logo-right").removeClass("navbar-fixed");
            $(".navbar-logo").addClass("navbar-initial");
            $(".navbar-logo-right").addClass("navbar-initial");
            $(".navbar-bottom").css("padding", "");
        }
    });
}

function scrollTop() {
    window.scrollTo(0, 0);
}

var navClick = 0;
$("#root").on("click", "#navbar_button", function () {
    navClick++;
    if (navClick % 2 > 0) {
        $("#navbar_button_1").css(
            "transform",
            "rotate(45deg) translateZ(0px) translateY(4px) translateX(5px)"
        );
        $("#navbar_button_2").css("opacity", "0");
        $("#navbar_button_3").css(
            "transform",
            "rotate(-45deg) translateZ(0px) translateY(-7px) translateX(7px)"
        );
        $(".navbar-menu").show();
        setTimeout(() => {
            $(".navbar-menu-bottom").css("opacity", "1");
        }, 50);
        setTimeout(() => {
            $(".navbar-menu-top").css("opacity", "1");
        }, 100);
        setTimeout(() => {
            $("#navbar_menu_top_item_1").css({
                opacity: "1",
                transform: "translateX(0)",
            });
        }, 150);
        setTimeout(() => {
            $("#navbar_menu_top_item_2").css({
                opacity: "1",
                transform: "translateX(0)",
            });
        }, 200);
        setTimeout(() => {
            $("#navbar_menu_top_item_3").css({
                opacity: "1",
                transform: "translateX(0)",
            });
        }, 250);
    } else if (navClick % 2 == 0) {
        resetNavbar();
    }
});

$("#root").on("click", "#navbar_menu_bottom", function () {
    navClick++;
    $("#navbar_button_1").css("transform", "");
    $("#navbar_button_2").css("opacity", "");
    $("#navbar_button_3").css("transform", "");
    setTimeout(() => {
        $(".navbar-menu-bottom").css("opacity", "1");
        $(".navbar-menu-responsive").hide();
    }, 300);
    setTimeout(() => {
        $(".navbar-menu-top").css("opacity", "");
    }, 250);
    setTimeout(() => {
        $("#navbar_menu_top_item_1").css({ opacity: "", transform: "" });
    }, 200);
    setTimeout(() => {
        $("#navbar_menu_top_item_2").css({ opacity: "", transform: "" });
    }, 150);
    setTimeout(() => {
        $("#navbar_menu_top_item_3").css({ opacity: "", transform: "" });
    }, 100);
});

var dropDown1Click = 0;
$("#root").on("click", "#navbar_menu_top_item_1", function () {
    dropDown1Click++;
    if (dropDown1Click % 2 > 0) {
        $("#navbar_menu_top_item_1_dropdown").css({
            height: "auto",
            opacity: "1",
        });
    } else if (dropDown1Click % 2 == 0) {
        $("#navbar_menu_top_item_1_dropdown").css({ height: "", opacity: "" });
    }
});

var dropDown2Click = 0;
$("#root").on("click", "#navbar_menu_top_item_2", function () {
    dropDown2Click++;
    if (dropDown2Click % 2 > 0) {
        $("#navbar_menu_top_item_2_dropdown").css({
            height: "auto",
            opacity: "1",
        });
    } else if (dropDown2Click % 2 == 0) {
        $("#navbar_menu_top_item_2_dropdown").css({ height: "", opacity: "" });
    }
});

$("#root").on("click", "#navbar_menu_bottom", function () {
    navClick++;
    scrollTop();
    resetNavbar();
});

$("#root").on("click", "#navbar_menu_top_icon", function () {
    navClick++;
    scrollTop();
    resetNavbar();
});

$("#root").on("click", "#navbar_menu_top_item_3", function () {
    navClick++;
    scrollTop();
    resetNavbar();
});

$("#root").on("click", ".navbar-menu-top-item-dropdown-item", function () {
    navClick++;
    scrollTop();
    resetNavbar();
});

function resetNavbar() {
    $("#navbar_button_1").css("transform", "");
    $("#navbar_button_2").css("opacity", "");
    $("#navbar_button_3").css("transform", "");
    setTimeout(() => {
        $(".navbar-menu-bottom").css("opacity", "1");
        $(".navbar-menu").hide();
    }, 300);
    setTimeout(() => {
        $(".navbar-menu-top").css("opacity", "");
    }, 250);
    setTimeout(() => {
        $("#navbar_menu_top_item_1").css({ opacity: "", transform: "" });
    }, 200);
    setTimeout(() => {
        $("#navbar_menu_top_item_2").css({ opacity: "", transform: "" });
    }, 150);
    setTimeout(() => {
        $("#navbar_menu_top_item_3").css({ opacity: "", transform: "" });
    }, 100);
    setTimeout(() => {
        $("#navbar_menu_top_item_4").css({ opacity: "", transform: "" });
    }, 50);
}

$("#root").on("mouseover", "#drop_down_menu_prd", function () {
    $("#produit_menu_prd").show();
});

$("#root").on("mouseleave", "#drop_down_menu_prd", function () {
    $("#produit_menu_prd").hide();
});

$("#root").on("mouseover", '[id^="produit_menu_menu_prd_"]', function () {
    let id = $(this).attr("id").split("_")[4];
    $('[id^="drop_down_menu_menu_"]').hide();
    $("#drop_down_menu_menu_" + id).show();
});

$("#root").on("mouseleave", '[id^="drop_down_menu_menu_"]', function () {
    let id = $(this).attr("id").split("_")[3];
    $("#drop_down_menu_menu_" + id).hide();
});

$("#root").on("click", "#produit_menu_prd", function () {
    $(this).hide();
    $('[id^="drop_down_menu_menu_"]').hide();
});

$("#root").on("mouseover", "#drop_down_menu_qsn", function () {
    $("#produit_menu_qsn").show();
});

$("#root").on("mouseleave", "#drop_down_menu_qsn", function () {
    $("#produit_menu_qsn").hide();
});

$("#root").on("click", "#produit_menu_qsn", function () {
    $(this).hide();
});

$("#root").on("mouseover", "#drop_down_menu_fll", function () {
    $("#produit_menu_fll").show();
});

$("#root").on("mouseleave", "#drop_down_menu_fll", function () {
    $("#produit_menu_fll").hide();
});

$("#root").on("click", "#produit_menu_fll", function () {
    $(this).hide();
});

export default Navbar;
