import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import VERRE_LABORATOIRE_BACKGROUND from "../../pictures/verre_laboratoire_background.jpg";
import VERRE_DECOUPAGE_1 from "../../pictures/machine_de_decoupe_et_façonnage.JPG";

function ProduitSomiver() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });

    return (
        <div
            className="produit-enava"
            style={{ height: "calc(100vh - 150px)" }}
        >
            <div className="cleaner"></div>
            <div className="prd-container">
                <NavLink exact to="/ProduitSomiverLaboratoire">
                    <div className="produit-presentation">
                        <img src={VERRE_LABORATOIRE_BACKGROUND} alt="" />
                        <div className="produit-description">
                            <p>laboratoire</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/produitSomiverFaconnage">
                    <div className="produit-presentation">
                        <img
                            src="/images/table_de_decoupe _du_verre.jpg"
                            alt=""
                        />
                        <div className="produit-description">
                            <p>Découpe et façonnage de verre</p>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitSomiver;
