import React from "react";
import ABRASIMG1 from "../../pictures/abras3.jpg";
import ABRASIMG2 from "../../pictures/abras_2.JPG";

function Abras() {
    return (
        <div className="filiale-container">
            <div className="cleaner"></div>
            <div className="filiale-title">
                <h2>Filiale Abras</h2>
            </div>
            <div className="filiale-grid">
                <div className="filiale-grid-left">
                    <div class="filiale-web-description">
                        <p>
                            Le projet a été initié par la SNIC. Le Groupe
                            Englais CHEM System avait été engagé pour étudier le
                            marché des abrasifs en Algérie. L'étude avait pris
                            en compte les besoins industriels générés par les
                            grands secteurs de la mécanique, de la métallurgie,
                            des hydrocarbures et de la transformation du bois.
                            L'usine a été construite par la société UNIVERSALE
                            BAU, elle est entrée en exploitation en 1982,
                            rattachée à l'ENAVA suite à la restructuration de la
                            SNIC. En février 1997, l'unité des abrasifs a été
                            transformée en société par actions et est devenue
                            Filiale ABRAS Spa.
                        </p>
                    </div>
                    <div className="filiale-description-flex">
                        <div>
                            <h4>Dénomination :</h4>
                            <p>
                                Société des produits abrasifs, par abréviation
                                ABRAS - SPA.
                            </p>
                        </div>
                        <div>
                            <h4>Adresse :</h4>
                            <p>
                                Zone industrielle SAIDA B.P. 143 Hai Ennasr
                                SAIDA
                            </p>
                        </div>
                        <div>
                            <h4>Date de création :</h4>
                            <p>1982</p>
                        </div>
                        <div>
                            <h4>Tél :</h4>
                            <p>+213 48.42.06.59</p>
                        </div>
                        <div>
                            <h4>Fax :</h4>
                            <p>+213 48.42.06.55</p>
                        </div>
                        <div>
                            <h4>E-mail :</h4>
                            <p>filialeabras_dz@hotmail.com</p>
                        </div>
                        <div>
                            <h4>Site Web :</h4>
                            <p>www.abras-spa.com</p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>Activités</h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>Abrasifs liés :</p>
                            <p>
                                • Meules de rectification, meulettes et meules
                                sur tige
                            </p>
                            <p>• Disques à meuler et tronçonner</p>
                            <p>Abrasifs appliqués :</p>
                            <p>• Bande en papier et toile</p>
                            <p>• Feuilles émeri</p>
                            <p>• Disque et rouleaux</p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>
                            Les domaine d'activité des produits ABRAS sont
                            variés:
                        </h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>
                                 Il sont utilisés dans plusieurs industries pour effectuer les travaux suivants  </p>
                                 <p> • Pour les abrasifs liés (rectification, affûtage, ébarbage, tronçonnage, dégrossissage et meulage par friction) </p>
                                 <p> • Pour les abrasifs appliqués (pnçage de bois, de peinture, plastique et caoutchouc) et verre sous arrosage).
                                 <p> • Pour les abrasifs de sablage (traitement de surface, décapage avant peinture, suppression de la rouille et réalisation de rugosité avant application de peinture ou revêtement)    </p>  {" "}
                            </p>
                            <p>
                                
                            </p>
                        </div>
                    </div>
                </div>
                <div className="filiale-grid-right">
                    <div className="filiale-description-image">
                        <img src={ABRASIMG1} alt="" />
                    </div>
                    <div className="filiale-sub-description-image">
                        <img src={ABRASIMG2} alt="" />
                    </div>
                    <div
                        className="filiale-sub-description-image"
                        style={{ marginTop: "40px" }}
                    >
                        <img src="/images/MACHINE_2.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default Abras;
