import React, { useState } from "react";
import VERRE_LABORATOIRE_1_DESCRIPTION from "../../pictures/Atelier_verre_technique.JPG";
import VERRE_LABORATOIRE_2_DESCRIPTION from "../../pictures/Atelier_verre_technique_1.JPG";
import VERRE_LABORATOIRE_3_DESCRIPTION from "../../pictures/Dual-Wall-Vacuum-Quartz.jpg";
import VERRE_LABORATOIRE_4_DESCRIPTION from "../../pictures/entonnoirs-en-verre-borosilicate-100.jpeg";
import VERRE_LABORATOIRE_5_DESCRIPTION from "../../pictures/mqdefault.jpg";
import VERRE_LABORATOIRE_6_DESCRIPTION from "../../pictures/verrerie_volumetrique.jpg";
import VERRE_LABORATOIRE_7_DESCRIPTION from "../../pictures/verre_somiver_description_5_background.jpg";
import VERRE_LABORATOIRE_8_DESCRIPTION from "../../pictures/verre_laboratoire_description_1_background.jpg";

function ProduitSomiverLaboratoire() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style6, setstyle6] = useState({ div: null, image: null });
    const [style7, setstyle7] = useState({ div: null, image: null });
    const [style8, setstyle8] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>VERRERIE DE LABORATOIRE</h2>
                <h3>Caractéristique physique :</h3>
                <p style={{ color: "#ff1d0c" }}>
                    Coefficient linéaire de dilatation (20/300C°) :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        3.3 10-6K -1
                    </span>
                </p>
                <p style={{ color: "#ff1d0c" }}>
                    Température maximale admissible pouvant être appliquée
                    pendant un court laps du temps :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        500° C
                    </span>
                </p>
                <p style={{ color: "#ff1d0c" }}>
                    Température de transformation (DIN52324) :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        530°C
                    </span>
                </p>
                <p style={{ color: "#ff1d0c" }}>
                    Choc thermique :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        Dt=280°C
                    </span>
                </p>
                <h3>Caractéristique chimique :</h3>
                <p style={{ color: "#ff1d0c" }}>
                    Principaux composante chimique :
                </p>
                <p>81% en poids SiO2</p>
                <p>13% en poids B2O2</p>
                <p>4% en poids Na2O/K2O</p>
                <p>2% en poids Al2O3</p>
                <p style={{ color: "#ff1d0c" }}>
                    Résistance hydrolytique selon DIN 12111/ ISO 719 Classe :{" "}
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        1
                    </span>
                </p>
                <p style={{ color: "#ff1d0c" }}>
                    Résistance aux acides selon DIN12116 Classe :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        1
                    </span>
                </p>
                <p style={{ color: "#ff1d0c" }}>
                    Résistance aux alcalins selon DIN52322/ISO 695 Classe :
                    <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        1
                    </span>
                </p>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_LABORATOIRE_1_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_LABORATOIRE_2_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_LABORATOIRE_3_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle3({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_LABORATOIRE_4_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_LABORATOIRE_5_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style6.div}>
                            <button
                                onClick={() =>
                                    setstyle6({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style6.image}
                                src={VERRE_LABORATOIRE_6_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle6({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style7.div}>
                            <button
                                onClick={() =>
                                    setstyle7({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style7.image}
                                src={VERRE_LABORATOIRE_7_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle7({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style8.div}>
                            <button
                                onClick={() =>
                                    setstyle8({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style8.image}
                                src={VERRE_LABORATOIRE_8_DESCRIPTION}
                                alt=""
                                onClick={() => {
                                    setstyle8({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitSomiverLaboratoire;
