import React from "react";
import { NavLink } from "react-router-dom";
import PRODUIT_APPLIQUE from "../../pictures/produit-applique-background.png";
import PRODUIT_LIE from "../../pictures/produit-liee-background.png";
import PRODUIT_SABLAGE from "../../pictures/produit-sablage-background.png";

function ProduitAbrasif() {
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="prd-container">
                <NavLink exact to="/ProduitAbrasifLiee">
                    <div className="produit-presentation">
                        <img src={PRODUIT_LIE} alt="" />
                        <div className="produit-description">
                            <p>produits liées</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAbrasifApplique">
                    <div className="produit-presentation">
                        <img src={PRODUIT_APPLIQUE} alt="" />
                        <div className="produit-description">
                            <p>produits appliqués</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitAbrasifSablage">
                    <div className="produit-presentation">
                        <img src={PRODUIT_SABLAGE} alt="" />
                        <div className="produit-description">
                            <p>produits sablage</p>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAbrasif;
