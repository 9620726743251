import React from "react";
import { NavLink } from "react-router-dom";
import VERRE_PRESSE from "../../pictures/download.png";
import VERRE_AGRO_ALIMENTAIRE from "../../pictures/pickled-vegetables-scully.jpg";
import VERRE_PHARMACETIQUE from "../../pictures/verre_pharmacetique_description_3_background.jpg";

function ProduitNover() {
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="prd-container">
                <NavLink exact to="/ProduitNoverAgroAlimentaire">
                    <div className="produit-presentation">
                        <img src={VERRE_AGRO_ALIMENTAIRE} alt="" />
                        <div className="produit-description">
                            <p>Emballage agro-alimentaire</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitNoverPharmacetique">
                    <div className="produit-presentation">
                        <img src={VERRE_PHARMACETIQUE} alt="" />
                        <div className="produit-description">
                            <p>Flaconnage pharmaceutique</p>
                        </div>
                    </div>
                </NavLink>
                <NavLink exact to="/ProduitNoverPresse">
                    <div className="produit-presentation">
                        <img src={VERRE_PRESSE} alt="" />
                        <div className="produit-description">
                            <p>verrerie de table</p>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitNover;
