import React from 'react'
import $ from 'jquery'; 

function contact() {
    return (
          <div class="contact-container">
            <div className="cleaner"></div>
                <h2>Contactez nous</h2>
                <div className='contact-form'>
                    <div className='contact-information'>
                        
                        <p>GROUPE INDUSTRIEL DES VERRES ET ABRASIFS - ENAVA SPA -</p>
                        <p> SIEGE SOCIAL : ZHUN USTO BP 4073 IBN ROCHD – ORAN –</p>
                        
                        <p>Tél : <strong>041 82 25 76/81</strong></p>
                        
                    <p>Fax : <strong>041 82 25 82/83</strong></p>
                        
                        <p>Mail : <strong>dg@groupe-enava.dz</strong></p>
                    </div>
                   <div className='contact-form-input'>
                        <p>Pour tous renseignements ou informations, remplissez le formulaire de contact ci-dessous ou nous joignez par téléphone.</p>
                        <div className="input">
                            <input type="text" id="nom_user" autoComplete="off"/>
                            <span className="nom-user">Nom et prenom</span>
                        </div>
                        <div className="input">
                            <input type="text" id="tlph_user" autoComplete="off"/>
                            <span className="tlph-user">Numéro de téléphone</span>
                        </div>
                        <div className="input">
                            <input type="email" id="email_user" autoComplete="off"/>
                            <span className="email-user">Email</span>
                        </div>
                        <div className="input textarea">
                            <textarea id="message_user" autoComplete="off"></textarea>
                            <span className="message-user">Message</span>
                        </div>
                        <div className="contact-button">
                            <button id="send_message">Envoyer</button>
                            <div></div>
                        </div>
                    </div> 
                </div>
                
                <div className="cleaner"></div>
            </div>
        
    )
}

$('#root').on('focus','.input input',function(){
    var id = $(this).attr('id');
    if (id == 'nom_user') {
        $('.nom-user').addClass('active-span');
    }
    if (id == 'tlph_user') {
        $('.tlph-user').addClass('active-span');
    }
    if (id == 'email_user') {
        $('.email-user').addClass('active-span');
    }
})

$('#root').on('focus','.input textarea',function(){
    var id = $(this).attr('id');
    if (id == 'message_user') {
        $('.message-user').addClass('active-span');
    }
})

export default contact
