import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Acceuil from "./components/acceuil/Acceuil";
import Historique from "./components/historique/Historique";
import MotDuPdg from "./components/motdupdg/MotDuPdg";
import Somiver from "./components/filiales/Somiver";
import Africaver from "./components/filiales/Africaver";
import Nover from "./components/filiales/Nover";
import Abras from "./components/filiales/Abras";
import ProduitAfricaver from "./components/produit-africaver/ProduitAfricaver";
import ProduitAfricaverTrempe from "./components/produit-africaver/ProduitAfricaverTrempe";
import ProduitAfricaverSecuriteBatiment from "./components/produit-africaver/ProduitAfricaverSecuriteBatiment";
import ProduitAfricaverSilicateDeSoude from "./components/produit-africaver/ProduitAfricaverSilicateDeSoude";
import ProduitAfricaverSecuriteAutomobile from "./components/produit-africaver/ProduitAfricaverSecuriteAutomobile";
import ProduitAfricaverImprime from "./components/produit-africaver/ProduitAfricaverImprime";

import ProduitNover from "./components/produit-nover/ProduitNover";
import ProduitNoverVerreriedetable from "./components/produit-nover/ProduitNoverVerreriedetable";
import ProduitNoverAgroAlimentaire from "./components/produit-nover/ProduitNoverAgroAlimentaire";
import ProduitNoverPharmacetique from "./components/produit-nover/ProduitNoverPharmacetique";

import ProduitAbrasif from "./components/produit-abrasif/ProduitAbrasif";
import ProduitAbrasifApplique from "./components/produit-abrasif/ProduitAbrasifApplique";
import ProduitAbrasifLiee from "./components/produit-abrasif/ProduitAbrasifLiee";
import ProduitAbrasifSablage from "./components/produit-abrasif/ProduitAbrasifSablage";

import ProduitSomiver from "./components/produit-somiver/ProduitSomiver";
import ProduitSomiverLaboratoire from "./components/produit-somiver/ProduitSomiverLaboratoire";
import ProduitSomiverFaconnage from "./components/produit-somiver/produitSomiverFaconnage";

import evenement from "./components/evenement/evenement";
import contact from "./components/contact/contact";
import Footer from "./components/footer/Footer";

import ScrollIntoView from "./components/ScrollIntoView";

import "./App.css";
import "./components/navbar/Navbar.css";
import "./components/acceuil/Acceuil.css";
import "./components/historique/Historique.css";
import "./components/motdupdg/MotDuPdg.css";
import "./components/contact/contact.css";
import "./components/footer/Footer.css";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Navbar />
                <ScrollIntoView>
                    <Switch>
                        <Route exact path="/" component={Acceuil} />
                        <Route
                            exact
                            path="/Historique"
                            component={Historique}
                        />
                        <Route exact path="/MotDuPdg" component={MotDuPdg} />
                        <Route exact path="/Evenement" component={evenement} />
                        <Route exact path="/Contact" component={contact} />

                        <Route exact path="/Somiver" component={Somiver} />
                        <Route exact path="/Africaver" component={Africaver} />
                        <Route exact path="/Nover" component={Nover} />
                        <Route exact path="/Abras" component={Abras} />

                        <Route
                            exact
                            path="/ProduitAfricaver"
                            component={ProduitAfricaver}
                        />
                        <Route
                            exact
                            path="/ProduitAfricaverTrempe"
                            component={ProduitAfricaverTrempe}
                        />
                        <Route
                            exact
                            path="/ProduitAfricaverSecuriteBatiment"
                            component={ProduitAfricaverSecuriteBatiment}
                        />
                        <Route
                            exact
                            path="/ProduitAfricaverSilicateDeSoude"
                            component={ProduitAfricaverSilicateDeSoude}
                        />
                        <Route
                            exact
                            path="/ProduitAfricaverSecuriteAutomobile"
                            component={ProduitAfricaverSecuriteAutomobile}
                        />
                        <Route
                            exact
                            path="/ProduitAfricaverImprime"
                            component={ProduitAfricaverImprime}
                        />

                        <Route
                            exact
                            path="/ProduitNover"
                            component={ProduitNover}
                        />
                        <Route
                            exact
                            path="/ProduitNoverVerreriedetable"
                            component={ProduitNoverVerreriedetable}
                        />
                        <Route
                            exact
                            path="/ProduitNoverAgroAlimentaire"
                            component={ProduitNoverAgroAlimentaire}
                        />
                        <Route
                            exact
                            path="/ProduitNoverPharmacetique"
                            component={ProduitNoverPharmacetique}
                        />

                        <Route
                            exact
                            path="/ProduitAbrasif"
                            component={ProduitAbrasif}
                        />
                        <Route
                            exact
                            path="/ProduitAbrasifApplique"
                            component={ProduitAbrasifApplique}
                        />
                        <Route
                            exact
                            path="/ProduitAbrasifLiee"
                            component={ProduitAbrasifLiee}
                        />
                        <Route
                            exact
                            path="/ProduitAbrasifSablage"
                            component={ProduitAbrasifSablage}
                        />

                        <Route
                            exact
                            path="/ProduitSomiver"
                            component={ProduitSomiver}
                        />
                        <Route
                            exact
                            path="/ProduitSomiverLaboratoire"
                            component={ProduitSomiverLaboratoire}
                        />
                        <Route
                            exact
                            path="/ProduitSomiverFaconnage"
                            component={ProduitSomiverFaconnage}
                        />
                    </Switch>
                </ScrollIntoView>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
