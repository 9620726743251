import React, { useState } from "react";
import VERRE_TABLE_1 from "../../pictures/verre_table_description_1_background.jpg";
import VERRE_TABLE_2 from "../../pictures/verre_table_description_2_background.png";
import VERRE_TABLE_3 from "../../pictures/verre_table_description_3_background.png";
import VERRE_TABLE_4 from "../../pictures/verre_table_description_4_background.jpg";
import VERRE_TABLE_5 from "../../pictures/verre_table_description_5_background.jpg";
import VERRE_TABLE_6 from "../../pictures/verre_table_description_6_background.jpg";
import VERRE_TABLE_7 from "../../pictures/verre_table_description_7_background.jpg";
import VERRE_TABLE_8 from "../../pictures/verre_table_description_8_background.jpg";
import VERRE_TABLE_9 from "../../pictures/verre_table_description_9_background.jpg";

import VERRE_TABLE_10 from "../../pictures/assiette_grand_modele.jpg";
import VERRE_TABLE_11 from "../../pictures/assiette_grand_modele.jpg";
import VERRE_TABLE_12 from "../../pictures/verre.jpg";

function ProduitNoverVerreriedetable() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style6, setstyle6] = useState({ div: null, image: null });
    const [style7, setstyle7] = useState({ div: null, image: null });
    const [style8, setstyle8] = useState({ div: null, image: null });
    const [style9, setstyle9] = useState({ div: null, image: null });
    const [style10, setstyle10] = useState({ div: null, image: null });
    const [style11, setstyle11] = useState({ div: null, image: null });
    const [style12, setstyle12] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>Verrerie de table</h2>
                <p
                    style={{
                        margin: "20px 0",
                        color: "red",
                        textTransform: "capitalize",
                    }}
                >
                    Activité à l'arrêt temporairement pour rénovation du four de
                    fusion verre.
                </p>
                <p>• Assiette</p>
                <p>• Verre à eau</p>
                <p>• Tasse à café</p>
                <p>• Tasse à thé</p>
                <p>• Sous tasse</p>
                <p>• Chope</p>
                <p>• Bol</p>
                <p>• Ramequin</p>

                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_TABLE_1}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_TABLE_2}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_TABLE_3}
                                alt=""
                                onClick={() => {
                                    setstyle3({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_TABLE_5}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_TABLE_6}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style6.div}>
                            <button
                                onClick={() =>
                                    setstyle6({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style6.image}
                                src={VERRE_TABLE_7}
                                alt=""
                                onClick={() => {
                                    setstyle6({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style8.div}>
                            <button
                                onClick={() =>
                                    setstyle8({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style8.image}
                                src={VERRE_TABLE_8}
                                alt=""
                                onClick={() => {
                                    setstyle8({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style7.div}>
                            <button
                                onClick={() =>
                                    setstyle7({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style7.image}
                                src={VERRE_TABLE_9}
                                alt=""
                                onClick={() => {
                                    setstyle7({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style9.div}>
                            <button
                                onClick={() =>
                                    setstyle9({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style9.image}
                                src={VERRE_TABLE_4}
                                alt=""
                                onClick={() => {
                                    setstyle9({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style10.div}>
                            <button
                                onClick={() =>
                                    setstyle10({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style10.image}
                                src={VERRE_TABLE_10}
                                alt=""
                                onClick={() => {
                                    setstyle10({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style11.div}>
                            <button
                                onClick={() =>
                                    setstyle11({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style11.image}
                                src={VERRE_TABLE_11}
                                alt=""
                                onClick={() => {
                                    setstyle11({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style12.div}>
                            <button
                                onClick={() =>
                                    setstyle12({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style12.image}
                                src={VERRE_TABLE_12}
                                alt=""
                                onClick={() => {
                                    setstyle12({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitNoverVerreriedetable;
