import React from "react";
import SOMIVER1 from "../../pictures/ENTREE_SOMIVER.JPG";
import SOMIVER2 from "../../pictures/machine_de_sablage_du_verre.JPG";

function Somiver() {
    return (
        <div className="filiale-container">
            <div className="cleaner"></div>
            <div className="filiale-title">
                <h2>Filiale Somiver</h2>
            </div>
            <div className="filiale-grid">
                <div className="filiale-grid-left">
                    <div class="filiale-web-description">
                        <p>
                            La filiale Somiver Spa a été créee en 1979 par la
                            Société Nationale des Industries Chimiques sous
                            l'appellation Unité de Miroiterie de Thénia (UMT){" "}
                        </p>
                        <p>
                            Elle intègre l'entreprise ENAVA en 1982 suite à la
                            restructuration organique de la SNIC{" "}
                        </p>
                        <p>
                            En 1997, transformation de l'unité Miroiterie de
                            Thénia en EPE / Spa, et devient filiale du Groupe
                            ENAVA, sous la dénomination "Somiver"{" "}
                        </p>
                    </div>
                    <div className="filiale-description-flex">
                        <div>
                            <h4>Dénomination :</h4>
                            <p>
                                Société Miroiterie et Verre Technique SOMIVER
                                SPA
                            </p>
                        </div>
                        <div>
                            <h4>Adresse :</h4>
                            <p>Route Nationale N°05 BP.121 Thenia BOUMERDES</p>
                        </div>
                        <div>
                            <h4>Date de création :</h4>
                            <p>1979</p>
                        </div>
                        <div>
                            <h4>Tél :</h4>
                            <p>024.96.08.32</p>
                        </div>
                        <div>
                            <h4>Fax :</h4>
                            <p>024.96.08.30</p>
                        </div>
                        <div>
                            <h4>E-mail :</h4>
                            <p>somiver-enava@hotmail.com</p>
                        </div>
                        <div>
                            <h4>Domaine d’activité :</h4>
                            <p>
                                Production et commercialisation de miroiterie et
                                verrerie de laboratoire.
                            </p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>Activité</h3>
                    </div>
                    <div class="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p style={{ margin: "5px 0" }}>
                                Les activités principales :
                            </p>
                            <p style={{ margin: "5px 0" }}>
                                • Découpe, façonnage et sablage du verre plat
                            </p>
                            <p style={{ margin: "5px 0" }}>
                                • Produits de verrerie de laboratoire
                            </p>
                        </div>
                    </div>
                    <div className="filiale-sub-title">
                        <h3>
                            Les domaine d'activité des produits de Somiver sont
                            variés:
                        </h3>
                    </div>
                    <div className="filiale-sub-description">
                        <div className="filiale-sub-description-flex">
                            <p>
                                • Découpe, façonnage et sablage du verre plat,
                                destiné pour les besoins de la clientèle de
                                l'ameublement et de la décoration, du bâtiment
                                et de l'électroménager{" "}
                            </p>
                            <p>
                                • Produits de verrerie de laboratoire, destinés
                                aux universités, aux laboratoires, centres
                                hospitaliers et établissements scolaires.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="filiale-grid-right">
                    <div
                        className="filiale-description-image"
                        style={{ height: "300px", position: "relative" }}
                    >
                        <img
                            src={SOMIVER1}
                            alt=""
                            style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                    <div className="filiale-sub-description-image">
                        <img src={SOMIVER2} alt="" />
                    </div>
                    <div
                        className="filiale-sub-description-image"
                        style={{ marginTop: "20px" }}
                    >
                        <img
                            src="/images/table_de_decoupe _du_verre.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default Somiver;
