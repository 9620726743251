import React, { useState } from "react";
import VERRE_SECURITE_AUTOMOBILE from "../../pictures/verre_securite_automobile_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_1 from "../../pictures/verre_securite_automobile_description_1_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_2 from "../../pictures/verre_securite_automobile_description_2_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_3 from "../../pictures/verre_securite_automobile_description_3_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_4 from "../../pictures/verre_securite_automobile_description_4_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_5 from "../../pictures/verre_securite_automobile_description_5_background.jpg";
import VERRE_SECURITE_AUTOMOBILE_6 from "../../pictures/verre_securite_automobile_description_6_background.jpg";

function ProduitAfricaverSecuriteAutomobile() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style6, setstyle6] = useState({ div: null, image: null });
    const [style7, setstyle7] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>verre securité automobile</h2>
                <h3>Caractéristiques</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Caractéristiques</th>
                            <th>Pare brise</th>
                            <th>Vitre latéral et déflecteurs</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Epaisseur</td>
                            <td>à partir de 5.63 mm</td>
                            <td>3.5 à 6 mm</td>
                        </tr>
                        <tr>
                            <td>Dim max</td>
                            <td>2200 x 4000mm</td>
                            <td>1240 x 750mm bombé / 240 x 1240mm plat</td>
                        </tr>
                        <tr>
                            <td>Dim min</td>
                            <td>400 x 400 mm</td>
                            <td>200 x 200 mm</td>
                        </tr>
                        <tr>
                            <td>Teintes</td>
                            <td>
                                Clair, teinté, clair degradé, teinté dégradé
                            </td>
                            <td>Teinté, clair, bronze et vert</td>
                        </tr>
                    </tbody>
                </table>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_SECURITE_AUTOMOBILE_2}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_SECURITE_AUTOMOBILE_1}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div
                        className="produit-image-sub-description"
                        style={{ gridColumn: "2/4" }}
                    >
                        <div style={style7.div}>
                            <button
                                onClick={() =>
                                    setstyle7({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style7.image}
                                src={VERRE_SECURITE_AUTOMOBILE_6}
                                alt=""
                                onClick={() => {
                                    setstyle7({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_SECURITE_AUTOMOBILE_4}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style6.div}>
                            <button
                                onClick={() =>
                                    setstyle6({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style6.image}
                                src={VERRE_SECURITE_AUTOMOBILE_5}
                                alt=""
                                onClick={() => {
                                    setstyle6({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAfricaverSecuriteAutomobile;
