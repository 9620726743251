import React, { useState } from "react";

import VERRE_AGRO_ALIMENTAIRE_1 from "../../pictures/produit-sablage3.png";
import VERRE_AGRO_ALIMENTAIRE_2 from "../../pictures/produit-sablage2.png";
import VERRE_AGRO_ALIMENTAIRE_3 from "../../pictures/produit-sablage1.jpg";

function ProduitAbrasifApplique() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style8, setstyle8] = useState({ div: null, image: null });
    const [style10, setstyle10] = useState({ div: null, image: null });
    return (
        <div
            className="produit-enava"
            style={{ width: "90%", height: "calc(100vh - 100px)" }}
        >
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>abrasif sablage</h2>
                <p>
                    Les différents abrasifs de sablage : - Silicate de fer -
                    Silicate d'aluminium - Autres abrasifs de sablage.
                </p>
                <p>
                    Domaine d'utilisation : ils sont utilisés dans le traitement
                    de surface, décapage avant peinture, supression de la
                    rouille et réalisation de rugosité avant application de
                    peinture ou revêtement.
                </p>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                    {/* <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_AGRO_ALIMENTAIRE_1}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div> */}
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_AGRO_ALIMENTAIRE_2}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style8.div}>
                            <button
                                onClick={() =>
                                    setstyle8({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style8.image}
                                src={VERRE_AGRO_ALIMENTAIRE_3}
                                alt=""
                                onClick={() => {
                                    setstyle8({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAbrasifApplique;
