import React, { useState } from "react";
import VERRE_PHARMACETIQUE_1 from "../../pictures/verre_pharmacetique_description_1_background.png";
import VERRE_PHARMACETIQUE_2 from "../../pictures/verre_pharmacetique_description_2_background.png";
import VERRE_PHARMACETIQUE_3 from "../../pictures/verre_pharmacetique_description_3_background.jpg";
import VERRE_PHARMACETIQUE_4 from "../../pictures/verre_pharmacetique_description_4_background.jpg";

function ProduitNoverPharmacetique() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>Flaconnage pharmaceutique</h2>
                <p
                    style={{
                        margin: "20px 0",
                        color: "red",
                        textTransform: "capitalize",
                    }}
                >
                    à l'arrêt temporairement pour modernisation de la ligne
                    destinée à l'emballage flaconnage pharmaceutique.
                </p>
                <div class="produit-image-description">
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_PHARMACETIQUE_1}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_PHARMACETIQUE_2}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_PHARMACETIQUE_3}
                                alt=""
                                onClick={() => {
                                    setstyle3({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_PHARMACETIQUE_4}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitNoverPharmacetique;
