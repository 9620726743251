import React, { useState } from "react";
import VERRE_DECOUPAGE_1 from "../../pictures/decoupe_faconnage_du_verre.jpg";
import VERRE_DECOUPAGE_2 from "../../pictures/sablage_du_verre.jpg";
import VERRE_FACONNAGE_1 from "../../pictures/table_de_decoupe_du_verre.jpg";
import VERRE_FACONNAGE_2 from "../../pictures/verre_faconnage_description_2_background.jpg";

function ProduitSomiverFaconnage() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    return (
        <div className="produit-enava" style={{ height: "90%" }}>
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>Découpe et façonnage de verre</h2>
                <div
                    class="produit-image-description"
                    style={{
                        gridTemplateColumns: "repeat(2,1fr)",
                        width: "80%",
                    }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_DECOUPAGE_1}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_DECOUPAGE_2}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="produit-image-sub-description">
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_DECOUPAGE_2}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div> */}
                    <div className="produit-image-sub-description">
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_FACONNAGE_1}
                                alt=""
                                onClick={() => {
                                    setstyle3({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_FACONNAGE_2}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {/* <div className="produit-image-sub-description">
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_FACONNAGE_2}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitSomiverFaconnage;
