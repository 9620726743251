import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import LOGO_ENAVA from "../../pictures/logo_enava.png";

function Footer() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="footer">
                    <div className="footer-top">
                        <div className="footer-logo">
                            <img src={LOGO_ENAVA} alt="" />
                        </div>
                        <div className="footer-information">
                            <h5>inforamtion</h5>
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse perspiciatis necessitatibus atque deleniti placeat enim reprehenderit quisquam numquam commodi. Tempore?</p> */}
                        </div>
                        <div className="footer-spacer"></div>
                        <div className="footer-navigation">
                            <h5>navigation</h5>
                            <ul>
                                <li>
                                    <Link exact to="/Historique">
                                        Qui somme nous
                                    </Link>
                                </li>
                                <li>
                                    <Link exact to="/evenement">
                                        Evènements
                                    </Link>
                                </li>
                                <li>
                                    <Link exact to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>© CFCG 2021</p>
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery maxWidth={767}>
                <div className="footer">
                    <div className="footer-top-responsive">
                        <div className="footer-logo">
                            <img src={LOGO_ENAVA} alt="" />
                        </div>
                        <div className="footer-information-responsive">
                            <h5>inforamtion</h5>
                            {/* <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Esse perspiciatis
                                necessitatibus atque deleniti placeat enim
                                reprehenderit quisquam numquam commodi. Tempore?
                            </p> */}
                        </div>
                        <div className="footer-spacer"></div>
                        <div className="footer-navigation">
                            <h5>navigation</h5>
                            <ul>
                                <li>
                                    <a href="#">a propos</a>
                                </li>
                                <li>
                                    <a href="#">services</a>
                                </li>
                                <li>
                                    <a href="#">audit</a>
                                </li>
                                <li>
                                    <a href="#">contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>© CFCG 2021</p>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default Footer;
