import React, { useState } from "react";
import VERRE_TREMPE from "../../pictures/verre_trempe_background.jpg";
import VERRE_TREMPE_DESCRIPTION_1 from "../../pictures/verre_trempe_description_1_background.jpg";
import VERRE_TREMPE_DESCRIPTION_2 from "../../pictures/verre_trempe_description_2_background.jpg";
import VERRE_TREMPE_DESCRIPTION_3 from "../../pictures/verre_trempe_description_3_background.jpg";
import VERRE_TREMPE_DESCRIPTION_4 from "../../pictures/verre_trempe_description_4_background.jpg";

function ProduitAfricaverTrempe() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>verre trempé</h2>
                {/* <p style={{ margin: "20px 0", color: "red" }}>
                    Activité à l'arrêt temporairement pour rénovation du four de
                    fusion verre.
                </p> */}
                <h3>Caractéristiques</h3>
                <p>
                    Epaisseur : <span>4 à12 mm</span>
                </p>
                <p>
                    Dimension min : <span>200 x 200 mm</span>
                </p>
                <p>
                    Dimension max : <span>2400 à 1240 mm</span>
                </p>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(2,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_TREMPE}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_TREMPE_DESCRIPTION_1}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_TREMPE_DESCRIPTION_3}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_TREMPE_DESCRIPTION_4}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAfricaverTrempe;
