import React, { useState } from "react";
import VERRE_AGRO_ALIMENTAIRE_1 from "../../pictures/verre_agro_alimentaire_description_1_background.png";
import VERRE_AGRO_ALIMENTAIRE_2 from "../../pictures/verre_agro_alimentaire_description_2_background.png";
import VERRE_AGRO_ALIMENTAIRE_3 from "../../pictures/Bouteille_Fraicheur_25cl.jpg";
import VERRE_AGRO_ALIMENTAIRE_4 from "../../pictures/Bocaux_de_conserve.jpg";
import VERRE_AGRO_ALIMENTAIRE_5 from "../../pictures/verre_agro_alimentaire_description_5_background.png";
import VERRE_AGRO_ALIMENTAIRE_6 from "../../pictures/bocal_de_conserve.jpg";
import VERRE_AGRO_ALIMENTAIRE_7 from "../../pictures/verre_agro_alimentaire_description_7_background.png";
import VERRE_AGRO_ALIMENTAIRE_8 from "../../pictures/verre_agro_alimentaire_description_8_background.png";
import VERRE_AGRO_ALIMENTAIRE_9 from "../../pictures/bocal.jpg";
import VERRE_AGRO_ALIMENTAIRE_10 from "../../pictures/Huile_d_olive.jpg";
import VERRE_AGRO_ALIMENTAIRE_11 from "../../pictures/36-productZoom.jpg";
import VERRE_AGRO_ALIMENTAIRE_12 from "../../pictures/verre_agro_alimentaire_description_12_background.jpg";
function ProduitNoverAgroAlimentaire() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        minHeight: "90%",
        maxHeight: "90%",
        width: "auto",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style2, setstyle2] = useState({ div: null, image: null });
    const [style3, setstyle3] = useState({ div: null, image: null });
    const [style4, setstyle4] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style6, setstyle6] = useState({ div: null, image: null });
    const [style7, setstyle7] = useState({ div: null, image: null });
    const [style8, setstyle8] = useState({ div: null, image: null });
    const [style9, setstyle9] = useState({ div: null, image: null });
    const [style10, setstyle10] = useState({ div: null, image: null });
    const [style11, setstyle11] = useState({ div: null, image: null });
    const [style12, setstyle12] = useState({ div: null, image: null });
    return (
        <div className="produit-enava">
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>GAMME DE PRODUITS FABRIQUES PAR LA FILIALE NOVER</h2>
                <h3>
                    Emballage agro-alimentaire en verre (bocaux et bouteilles) :
                </h3>
                <p style={{ color: "#ff1d0c" }}>
                    Bocaux pour Produits alimentaire SMEN :
                </p>
                <p>- Contenance : 800 ml, 900 ml, 1600 ml, 1700 ml, 1800 ml</p>
                <p style={{ color: "#ff1d0c" }}>Bocaux pour Produit MIEL :</p>
                <p>- Contenance : 106 ml, 110 ml, 210 ml, 380 ml, 740 ml</p>
                <p style={{ color: "#ff1d0c" }}>
                    Bocaux pour Produits alimentaire Pâtes à Tartiner :
                </p>
                <p>- Contenance : 370 ml, 720 ml, 740 ml</p>
                <p style={{ color: "#ff1d0c" }}>
                    Bocaux pour Produits alimentaire Confitures :
                </p>
                <p>- Contenance : 212 ml, 240 ml, 314 ml, 370 ml, 720 ml</p>
                <p style={{ color: "#ff1d0c" }}>
                    Bocaux pour Produits alimentaire Sauces, Olive et cornichons
                    :
                </p>
                <p>
                    - Contenance : 212 ml, 250 ml, 370 ml, 446 ml, 580 ml, 720
                    ml, 740 ml, 1035 ml, 1062 ml, 1300 ml
                </p>
                <p style={{ color: "#ff1d0c" }}>Bouteille pour Jus et SODA</p>
                <p>- Contenance : 250 ml, 280 ml, 1000 ml </p>
                <p>- Contenance : 250 ml</p>
                <p style={{ color: "#ff1d0c" }}>
                    Bouteille pour Produits Huile d’Olive
                </p>
                <p>- Contenance : 250 ml, 300 ml, 370 ml</p>

                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style2.div}>
                            <button
                                onClick={() =>
                                    setstyle2({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style2.image}
                                src={VERRE_AGRO_ALIMENTAIRE_2}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style3.div}>
                            <button
                                onClick={() =>
                                    setstyle3({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style3.image}
                                src={VERRE_AGRO_ALIMENTAIRE_3}
                                alt=""
                                onClick={() => {
                                    setstyle2({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style4.div}>
                            <button
                                onClick={() =>
                                    setstyle4({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style4.image}
                                src={VERRE_AGRO_ALIMENTAIRE_4}
                                alt=""
                                onClick={() => {
                                    setstyle4({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>Bocaus Pates Tartiner</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_AGRO_ALIMENTAIRE_5}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style6.div}>
                            <button
                                onClick={() =>
                                    setstyle6({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style6.image}
                                src={VERRE_AGRO_ALIMENTAIRE_6}
                                alt=""
                                onClick={() => {
                                    setstyle6({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                        <div style={style7.div}>
                            <button
                                onClick={() =>
                                    setstyle7({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style7.image}
                                src={VERRE_AGRO_ALIMENTAIRE_9}
                                alt=""
                                onClick={() => {
                                    setstyle7({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style8.div}>
                            <button
                                onClick={() =>
                                    setstyle8({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style8.image}
                                src={VERRE_AGRO_ALIMENTAIRE_7}
                                alt=""
                                onClick={() => {
                                    setstyle8({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>Bocaux pour produits MIEL</p>
                        </div>
                        <div style={style9.div}>
                            <button
                                onClick={() =>
                                    setstyle9({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style9.image}
                                src={VERRE_AGRO_ALIMENTAIRE_8}
                                alt=""
                                onClick={() => {
                                    setstyle9({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>Bocaux SMEN</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style10.div}>
                            <button
                                onClick={() =>
                                    setstyle10({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style10.image}
                                src={VERRE_AGRO_ALIMENTAIRE_10}
                                alt=""
                                onClick={() => {
                                    setstyle10({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>Bouteille Huile D'olive</p>
                        </div>
                        <div style={style11.div}>
                            <button
                                onClick={() =>
                                    setstyle11({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style11.image}
                                src={VERRE_AGRO_ALIMENTAIRE_11}
                                alt=""
                                onClick={() => {
                                    setstyle11({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>Bouteille Jus</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitNoverAgroAlimentaire;
