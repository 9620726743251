import React from "react";
import MediaQuery from "react-responsive";

function MotDuPdg() {
    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>Mot du PDG</h2>
                    <h3>Chers Partenaires</h3>
                    <p>
                        Tout au long de ces 40 années, l’ENAVA, Groupe
                        Industriel des Verres et Abrasifs, issue de la
                        restructuration de la SNIC, à travers ses filiales ‘’
                        verres et abrasifs’’ a fait preuve d&#39;une
                        détermination et d&#39;une volonté constante de bâtir
                        des fondations durables pour son développement et sa
                        pérennité.
                    </p>
                    <p>
                        L&#39;efficacité d&#39;une société réside dans sa
                        capacité à surmonter les difficultés dans les moments
                        les plus difficiles et réagir aux évolutions du marché
                        et c&#39;est dans cette dynamique que nous avons décidé
                        de développer nos métiers afin d’accompagner notre
                        clientèle.
                    </p>
                    <p>
                        Trouver des opportunités là où résident de très grands
                        obstacles n’a pas été aussi facile pour notre entreprise
                        ‘’ ENAVA ‘’.
                    </p>
                    <p>
                        Grâce à l&#39;expérience de nos équipes et la
                        collaboration de nos clients, notre entreprise a pu
                        surmonter les défis pour pouvoir se positionner sur des
                        marchés concurrentiels et maintenir un rythme de
                        développement progressif.
                    </p>
                    <p>
                        Au nom de la famille ENAVA, nous tenons à adresser nos
                        sincères remerciements et notre sincère gratitude à tous
                        nos clients pour nous avoir accordé le privilège
                        d&#39;être leurs partenaires même dans les moments des
                        plus difficiles.
                    </p>
                    <p>
                        Forts de cette confiance, nous sommes plus que jamais
                        déterminés à persévérer sur la voie du développement et
                        de l&#39;amélioration continue de nos produits et
                        services dans le plein respect des règles éthiques.
                    </p>
                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="qsn-container">
                    <div className="cleaner"></div>
                    <h2>Mot du PDG</h2>
                    <h3>Chers Partenaires</h3>
                    <p>
                        Tout au long de ces 40 années, l’ENAVA, Groupe
                        Industriel des Verres et Abrasifs, isssue de la
                        restructuration de la SNIC, à travers ses filiales ‘’
                        verres et abrasifs’’ a fait preuve d&#39;une
                        détermination et d&#39;une volonté constante de bâtir
                        des fondations durables pour son développement et sa
                        pérennité.
                    </p>
                    <p>
                        L&#39;efficacité d&#39;une société réside dans sa
                        capacité à surmonter les difficultés dans les moments
                        les plus difficiles et réagir aux évolutions du marché
                        et c&#39;est dans cette dynamique que nous avons décidé
                        de développer nos métiers afin d’accompagner notre
                        clientèle.
                    </p>
                    <p>
                        Trouver des opportunités là où résident de très grands
                        obstacles n’a pas été aussi facile pour notre entreprise
                        ‘’ ENAVA ‘’.
                    </p>
                    <p>
                        Grâce à l&#39;expérience de nos équipes et la
                        collaboration de nos clients, notre entreprise a pu
                        surmonter les défis pour pouvoir se positionner sur des
                        marchés concurrentiels et maintenir un rythme de
                        développement progressif.
                    </p>
                    <p>
                        Au nom de la famille ENAVA, nous tenons à adresser nos
                        sincères remerciements et notre sincère gratitude à tous
                        nos clients pour nous avoir accordé le privilège
                        d&#39;être leurs partenaires même dans les moments des
                        plus difficiles.
                    </p>
                    <p>
                        Forts de cette confiance, nous sommes plus que jamais
                        déterminés à persévérer sur la voie du développement et
                        de l&#39;amélioration continue de nos produits et
                        services dans le plein respect des règles éthiques.
                    </p>
                    <div className="cleaner"></div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default MotDuPdg;
