import $ from "jquery";
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import LOGO_AFRICAVER from "../../pictures/Africaver.png";
import LOGO_SOMIVER from "../../pictures/logo_somiver.png";
import LOGO_NOVER from "../../pictures/logo_nover.gif";
import LOGO_ABRAS from "../../pictures/logo_abras.png";
import LOCALISATION from "../../pictures/localisation.PNG";

function Acceuil() {
    const location = useLocation();

    // useEffect(() => {
    //     $("#root").on(
    //         "mouseover",
    //         ".acceuil-services-presetation",
    //         function () {
    //             $(this).css("background", "#ff1d0c");
    //             $(this).find("p").css("color", "#fff");
    //             $(this).find("div").css("background", "rgb(15, 15, 15)");
    //             $(this).find("a").css("color", "#fff");
    //         }
    //     );

    //     $("#root").on(
    //         "mouseleave",
    //         ".acceuil-services-presetation",
    //         function () {
    //             $(this).css("background", "");
    //             $(this).find("p").css("color", "");
    //             $(this).find("div").css("background", "");
    //             $(this).find("a").css("color", "");
    //         }
    //     );

    //     $("#root").on("click", "#next", function () {
    //         clearInterval(intervalTime);
    //         nextSlide();
    //     });

    //     $("#root").on("click", "#prev", function () {
    //         clearInterval(intervalTime);
    //         prevSlide();
    //     });

    //     var slides = document.querySelectorAll(".slide");
    //     var next = document.querySelector("#next");
    //     var prev = document.querySelector("#prev");
    //     var auto = true;
    //     var intervalTime = 3000;
    //     let slideInterval;

    //     function nextSlide() {
    //         var current = document.querySelector(".current");
    //         current.classList.remove("current");
    //         if (current.nextElementSibling) {
    //             current.nextElementSibling.classList.add("current");
    //         } else {
    //             slides[0].classList.add("current");
    //         }
    //         // setTimeout(current.classList.remove("current"));
    //     }

    //     function prevSlide() {
    //         var current = document.querySelector(".current");
    //         current.classList.remove("current");
    //         if (current.previousElementSibling) {
    //             current.previousElementSibling.classList.add("current");
    //         } else {
    //             slides[slides.length - 1].classList.add("current");
    //         }
    //         // setTimeout(current.classList.remove("current"));
    //     }
    //     // if (next) {
    //     //     next.addEventListener("click", nextSlide);
    //     // } else if (prev) {
    //     //     prev.addEventListener("click", prevSlide);
    //     // }

    //     // if (auto) {
    //     //     setInterval(nextSlide, intervalTime);
    //     // }
    // }, []);

    return (
        <div>
            <MediaQuery minWidth={768}>
                <div className="acceuil">
                    <div className="acceuil-top">
                        {/* <div id="carousel">
                            <div id="slides">
                                <div className="slide current"></div>
                                <div className="slide"></div>
                                <div className="slide"></div>
                                <div className="slide"></div>
                            </div>
                            <div className="button">
                                <i
                                    id="prev"
                                    className="fas fa-arrow-circle-left"
                                ></i>
                                <i
                                    id="next"
                                    className="fas fa-arrow-circle-right"
                                ></i>
                            </div>
                        </div> */}
                        <Carousel>
                            <div style={{ height: "500px" }}>
                                <img
                                    style={{ height: "100%" }}
                                    src="/images/SLIDE1.JPG"
                                />
                            </div>
                            <div style={{ height: "500px" }}>
                                <img
                                    style={{ height: "100%" }}
                                    src="/images/SLIDE2.JPG"
                                />
                            </div>
                            <div style={{ height: "500px" }}>
                                <img
                                    style={{ height: "100%" }}
                                    src="/images/SLIDE3.JPG"
                                />
                            </div>
                            <div style={{ height: "500px" }}>
                                <img
                                    style={{ height: "100%", width: "auto" }}
                                    src="/images/SLIDE4.JPG"
                                />
                            </div>
                        </Carousel>
                    </div>
                    <div className="acceuil-title">
                        <h1>nos filiales</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-services">
                        <NavLink exact to="/Nover">
                            <div className="acceuil-services-presetation">
                                <img src={LOGO_NOVER} alt="" />
                                <p>NOVER</p>
                                <div></div>
                                <a>Découvrir</a>
                            </div>
                        </NavLink>
                        <NavLink exact to="/Africaver">
                            <div className="acceuil-services-presetation">
                                <img src={LOGO_AFRICAVER} alt="" />
                                <p>AFRICAVER</p>
                                <div></div>
                                <a>Découvrir</a>
                            </div>
                        </NavLink>
                        <NavLink exact to="/Somiver">
                            <div className="acceuil-services-presetation">
                                <img src={LOGO_SOMIVER} alt="" />
                                <p>SOMIVER</p>
                                <div></div>
                                <a>Découvrir</a>
                            </div>
                        </NavLink>
                        <NavLink exact to="/Abras">
                            <div className="acceuil-services-presetation">
                                <img src={LOGO_ABRAS} alt="" />
                                <p>ABRAS</p>
                                <div></div>
                                <a
                                    href="https://abras-spa.com"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    découvrir
                                </a>
                            </div>
                        </NavLink>
                    </div>
                    <div className="acceuil-title">
                        <h1>localisation</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-licalisation">
                        <img src={LOCALISATION} alt="" />
                    </div>
                    <div className="acceuil-title">
                        <h1>contactez-nous</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-contact">
                        <div className="acceuil-contact-top">
                            <h3>
                                Pour plus d'information n'hésitez pas à nous
                                contacter{" "}
                            </h3>
                        </div>
                        <div className="acceuil-contact-bottom">
                            <div>
                                <i className="fas fa-phone"></i>
                                <p style={{ marginTop: "20px" }}>
                                    Tel : 041 82 25 76/81{" "}
                                </p>

                                <p>Fax : 041 82 25 82/83</p>
                            </div>
                            <div>
                                <i className="fas fa-map-marker-alt"></i>
                                <p style={{ marginTop: "20px" }}>
                                    GROUPE INDUSTRIEL DES VERRES ET ABRASIFS
                                    ENAVA SPA{" "}
                                </p>
                                <p>
                                    HOLDING ACS "Algerie Chemical Specialities"
                                </p>
                                <p>
                                    SIEGE SOCIAL : ZHUN USTO BP 4073 IBN ROCHD –
                                    ORAN –
                                </p>
                            </div>
                            <div>
                                <i className="far fa-envelope"></i>
                                <p style={{ marginTop: "20px" }}>
                                    dg@groupe-enava.dz
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <div className="acceuil">
                    <div className="acceuil-top">
                        <div id="carousel">
                            <div id="slides">
                                <div className="slide current"></div>
                                <div className="slide"></div>
                                <div className="slide"></div>
                                <div className="slide"></div>
                            </div>
                            <div className="button">
                                <i
                                    id="prev"
                                    className="fas fa-arrow-circle-left"
                                ></i>
                                <i
                                    id="next"
                                    className="fas fa-arrow-circle-right"
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div className="acceuil-title">
                        <h1>nos filiales</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-services">
                        <div className="acceuil-services-presetation">
                            <img src={LOGO_AFRICAVER} alt="" />
                            <p>AFRICAVER</p>
                            <div></div>
                            <NavLink exact to="/Africaver">
                                découvrir
                            </NavLink>
                        </div>
                        <div className="acceuil-services-presetation">
                            <img src={LOGO_SOMIVER} alt="" />
                            <p>SOMIVER</p>
                            <div></div>
                            <NavLink exact to="/Somiver">
                                découvrir
                            </NavLink>
                        </div>
                        <div className="acceuil-services-presetation">
                            <img src={LOGO_NOVER} alt="" />
                            <p>NOVER</p>
                            <div></div>
                            <NavLink exact to="/Nover">
                                découvrir
                            </NavLink>
                        </div>
                        <div className="acceuil-services-presetation">
                            <img src={LOGO_ABRAS} alt="" />
                            <p>ABRAS</p>
                            <div></div>
                            <a
                                href="https://abras-spa.com"
                                target="_blank"
                                rel="noopener"
                            >
                                découvrir
                            </a>
                        </div>
                    </div>
                    <div className="acceuil-title">
                        <h1>localisation</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-licalisation">
                        <img src={LOCALISATION} alt="" />
                    </div>
                    <div className="acceuil-title">
                        <h1>contactez-nous</h1>
                        <div></div>
                    </div>
                    <div className="acceuil-contact">
                        <div className="acceuil-contact-top">
                            <h3>
                                Pour plus d'information n'hésitez pas à nous
                                contacter{" "}
                            </h3>
                        </div>
                        <div className="acceuil-contact-bottom">
                            <div>
                                <i className="fas fa-phone"></i>
                                <p>041 42 96 12 </p>
                                <p>041 42 96 14</p>
                            </div>
                            <div>
                                <i className="fas fa-map-marker-alt"></i>
                                <p>
                                    ENTREPRISE NATIONALE DES VERRES ET ABRASIFS
                                    GROUPE ENAVA SPA
                                </p>
                                <p>
                                    SOCIETE DE GESTION DES PARTICIPATIONS CHIMIE
                                    PHARMACIE – GEPHAC –
                                </p>
                                <p>ZHUN USTO BP 4073 IBN ROCHD – ORAN –</p>
                            </div>
                            <div>
                                <i className="far fa-envelope"></i>
                                <p>dg@groupe-enava.dz</p>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default Acceuil;
