import React, { useState } from "react";

import VERRE_AGRO_ALIMENTAIRE_1 from "../../pictures/produit-lie-ceramique.jpg";
import VERRE_AGRO_ALIMENTAIRE_2 from "../../pictures/produit-lie-ebarbages.jpg";
import VERRE_AGRO_ALIMENTAIRE_3 from "../../pictures/produit-lie-bakelite.jpg";
import VERRE_AGRO_ALIMENTAIRE_4 from "../../pictures/produit-lie-pierre.jpg";
import VERRE_AGRO_ALIMENTAIRE_5 from "../../pictures/produit-lie-tige.jpg";

function ProduitAbrasifApplique() {
    const divStyle = {
        width: "100%",
        height: "100%",
        backgournd: "#000",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
    };
    const imageStyle = {
        position: "absolute",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    };
    const [style1, setstyle1] = useState({ div: null, image: null });
    const [style5, setstyle5] = useState({ div: null, image: null });
    const [style8, setstyle8] = useState({ div: null, image: null });
    const [style10, setstyle10] = useState({ div: null, image: null });
    const [style11, setstyle11] = useState({ div: null, image: null });
    return (
        <div className="produit-enava" style={{ width: "90%" }}>
            <div className="cleaner"></div>
            <div className="produit-presentation-description">
                <h2>abrasif liées</h2>
                <p>
                    Les abrasifs liés sont utilisés dans plusieurs industries
                    pour effectuer les travaux suivants : rectification,
                    affûtage, ébarbage, dégrossissage, meulage par friction,
                    tronçonnage.
                </p>
                <p>
                    Les types de produits liés fabriqués : meules céramiques,
                    meules bakélites, pierres et limes, meules d’ébarbage,
                    meules de tronçonnage et meulettes et meules sur tiges.
                </p>
                <div
                    class="produit-image-description"
                    style={{ gridTemplateColumns: "repeat(5,1fr)" }}
                >
                    <div className="produit-image-sub-description">
                        <div style={style1.div}>
                            <button
                                onClick={() =>
                                    setstyle1({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style1.image}
                                src={VERRE_AGRO_ALIMENTAIRE_1}
                                alt=""
                                onClick={() => {
                                    setstyle1({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>MEULES ABRASIVES A LIANT CERAMIQUE</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style5.div}>
                            <button
                                onClick={() =>
                                    setstyle5({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style5.image}
                                src={VERRE_AGRO_ALIMENTAIRE_2}
                                alt=""
                                onClick={() => {
                                    setstyle5({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>DISQUES ABRASIFS A TRONÇONNER ET EBARBER</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style8.div}>
                            <button
                                onClick={() =>
                                    setstyle8({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style8.image}
                                src={VERRE_AGRO_ALIMENTAIRE_3}
                                alt=""
                                onClick={() => {
                                    setstyle8({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>MEULES ABRASIVESS A LIANT BAKELITE</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style10.div}>
                            <button
                                onClick={() =>
                                    setstyle10({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style10.image}
                                src={VERRE_AGRO_ALIMENTAIRE_4}
                                alt=""
                                onClick={() => {
                                    setstyle10({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>SEGMENTS, PIERRES ET LIMES</p>
                        </div>
                    </div>
                    <div className="produit-image-sub-description">
                        <div style={style11.div}>
                            <button
                                onClick={() =>
                                    setstyle11({ div: null, image: null })
                                }
                            >
                                X
                            </button>
                            <img
                                style={style11.image}
                                src={VERRE_AGRO_ALIMENTAIRE_5}
                                alt=""
                                onClick={() => {
                                    setstyle11({
                                        div: divStyle,
                                        image: imageStyle,
                                    });
                                }}
                            />
                            <p>MEULES SUR TIGES</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cleaner"></div>
        </div>
    );
}

export default ProduitAbrasifApplique;
